import React from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';

export const DropDown = (props) => {
    const t = usePhraseTranslater();
    const { children, prompt, ...childProps } = props;
    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <select {...childProps}>
            <option value={null} disabled>{prompt || t('Select One')}</option>
            {children}
        </select>
    );
};
