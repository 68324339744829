import React from 'react';
import { AccessibleButton } from '@silkpwa/module/react-component/accessible-button';
import { classes } from '@silkpwa/module/util/classes';
import styles from './style.css';

export const FilterHeader = ({ toggle, open, attribute }) => (
    <AccessibleButton
        tag="span"
        action={toggle}
        className={classes(styles.header, {
            [styles.open]: open,
        })}
    >
        {attribute.label}
    </AccessibleButton>
);
