import React from 'react';
import { GridSection } from '../grid-section';
import { PaginationSection } from '../pagination-section';
import styles from './styles.css';

export const MainContent = ({
    pageState,
    urls,
    actions,
    shouldDisplaySort,
    shouldShowRecommendations,
    emptyContent,
}) => (
    <div className={styles.mainContent}>
        <GridSection
            pageState={pageState}
            actions={actions}
            shouldDisplaySort={shouldDisplaySort}
            shouldShowRecommendations={shouldShowRecommendations}
            emptyContent={emptyContent}
        />
        <PaginationSection
            pageState={pageState}
            urls={urls}
        />
        <div className={styles.clear} />
    </div>
);
