import React from 'react';
import { UseState } from '@silkpwa/module/react-component/use-state';
import { getQueryParamByName } from 'ui/util/get-query-param-by-name';
import { useConfig } from '@silkpwa/module/ecommerce-catalog/config/use-config';
import { FilterHeader } from './filter-header';
import { FilterContent } from './filter-content';

export const createFilterAccordion = Content => (
    ({ attribute }) => {
        let startOpen = attribute.options.some(o => o.selected);
        const config = useConfig();
        const paramValue = getQueryParamByName('price');
        if (attribute.id === 'price' && paramValue) {
            startOpen = true;
        }
        if (config?.open_filters?.includes(attribute.id)) {
            startOpen = true;
        }

        return (
            <UseState initialState={startOpen}>
                {([open, setOpen]) => (
                    <div>
                        <FilterHeader
                            attribute={attribute}
                            open={open}
                            toggle={(ev) => {
                                ev.preventDefault();
                                setOpen(!open);
                            }}
                        />
                        <div style={{ display: open ? 'block' : 'none' }}>
                            <FilterContent>
                                <Content attribute={attribute} />
                            </FilterContent>
                        </div>
                    </div>
                )}
            </UseState>
        );
    }
);
