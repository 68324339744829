import { useMediaQuery } from 'react-responsive';

/* Commonly used */
const useDesktopMediaQuery = () => useMediaQuery({ minWidth: 1041 });

const useMobileMediaQuery = () => useMediaQuery({ maxWidth: 1040 });

/* Use this sparingly */
const useLargeMobileMediaQuery = () => useMediaQuery({ minWidth: 876, maxWidth: 1040 });

const useMediumMobileMediaQuery = () => useMediaQuery({ minWidth: 591, maxWidth: 875 });

const useSmallMobileMediaQuery = () => useMediaQuery({ minWidth: 401, maxWidth: 590 });

const useVerySmallMobileMediaQuery = () => useMediaQuery({ maxWidth: 400 });

/* Avoid using in new functionality */
const useLargeDesktopMediaQuery = () => useMediaQuery({ minWidth: 1440 });

const useSmallDesktopMediaQuery = () => useMediaQuery({ minWidth: 1041, maxWidth: 1439 });


/* Commonly used */
const Desktop = ({ children }) => {
    const isDesktop = useDesktopMediaQuery();

    return isDesktop ? children : null;
};

const Mobile = ({ children }) => {
    const isMobile = useMobileMediaQuery();

    return isMobile ? children : null;
};

/* Use this sparingly */
const LargeMobile = ({ children }) => {
    const isLargeMobile = useLargeMobileMediaQuery();

    return isLargeMobile ? children : null;
};

const MediumMobile = ({ children }) => {
    const isMediumMobile = useMediumMobileMediaQuery();

    return isMediumMobile ? children : null;
};

const SmallMobile = ({ children }) => {
    const isSmallMobile = useSmallMobileMediaQuery();

    return isSmallMobile ? children : null;
};

const VerySmallMobile = ({ children }) => {
    const isVerySmallMobile = useVerySmallMobileMediaQuery();

    return isVerySmallMobile ? children : null;
};

/* Avoid using in new functionality */
const LargeDesktop = ({ children }) => {
    const isLargeDesktop = useLargeDesktopMediaQuery();

    return isLargeDesktop ? children : null;
};

const SmallDesktop = ({ children }) => {
    const isSmallDesktop = useSmallDesktopMediaQuery();

    return isSmallDesktop ? children : null;
};

/* Commonly used */
export {
    useDesktopMediaQuery,
    useMobileMediaQuery,
    Desktop,
    Mobile,
};

/* Use this sparingly */
export {
    useLargeMobileMediaQuery,
    useMediumMobileMediaQuery,
    useSmallMobileMediaQuery,
    useVerySmallMobileMediaQuery,
    LargeMobile,
    MediumMobile,
    SmallMobile,
    VerySmallMobile,
};

/* Avoid using in new functionality */
export {
    useLargeDesktopMediaQuery,
    useSmallDesktopMediaQuery,
    LargeDesktop,
    SmallDesktop,
};
