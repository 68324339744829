import React from 'react';
import { PlaceholderImage } from 'ui/component/placeholders/image';
import { computeWidthStyle } from 'ui/util/compute-width';
import range from 'lodash/range';
import styles from './style.css';

const LoadingItem = ({ displayed }: {displayed: number}) => (
    <div className={styles.item} style={computeWidthStyle(displayed)}>
        <PlaceholderImage width="100%" className={styles.image} />
    </div>
);

const items = range(parseInt(process.env.PLPPageSize || '0', 10)).map((_, i) => ({ id: i }));

export const LoadingGrid = ({ columns }: { columns: number }) => (
    <div className={styles.gridWrap} data-test="loading-grid">
        <div className={styles.grid}>
            {items.map(item => (
                <LoadingItem key={item.id} displayed={columns} />
            ))}
        </div>
    </div>
);
