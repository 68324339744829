import React from 'react';
import { Sort } from '../../components/sort';
import styles from './styles.css';

export const SortSection = ({ pageState, actions, shouldDisplaySort }) => (
    <div className={styles.sortSection}>
        {shouldDisplaySort && (
            <div className={styles.sort}>
                <Sort
                    sortOptions={pageState.sortOptions}
                    setSort={actions.setSort}
                    sortBy={pageState.sortBy}
                    sortDir={pageState.sortDir}
                />
            </div>
        )}
    </div>
);
