import { connect } from '@silkpwa/redux';
import { Pagination } from '@silkpwa/module/pagination/pagination';
import { OverridePagination } from '@silkpwa/module/pagination/override-pagination';
import { watchComputedProps } from '../../util/watch-computed-props';

const getOverridePageState = (pagination: OverridePagination, state) => {
    const query = pagination.selectors.getQuery(state);
    const results = pagination.selectors.getResults(state);
    const pageCount = pagination.selectors.getPageCount(state);

    return {
        page: query.page + 1,
        pageSize: query.pageSize,
        sortBy: query.sortBy,
        sortDir: query.sortDir,
        filterValues: query.filters || {},
        pageCount,
        totalCount: results.totalCount,
        sortOptions: results.sortOptions,
        filterOptions: results.filterOptions,
        options: results.options,
        attributes: results.attributes,
        items: results.items,
        showing: {
            first: Math.min(results.totalCount, query.page * query.pageSize + 1),
            last: Math.min(results.totalCount, (query.page + 1) * query.pageSize),
        },
    };
};

const getPageState = (pagination: Pagination, state) => {
    const query = pagination.selectors.getQuery(state);
    const results = pagination.selectors.getResults(state);
    const pageCount = pagination.selectors.getPageCount(state);

    return {
        page: query.page + 1,
        pageSize: query.pageSize,
        sortBy: query.sortBy,
        sortDir: query.sortDir,
        filterValues: query.filters || {},
        pageCount,
        totalCount: results.totalCount,
        sortOptions: results.sortOptions,
        filterOptions: results.filterOptions,
        options: results.options,
        attributes: results.attributes,
        items: results.items,
        showing: {
            first: Math.min(results.totalCount, query.page * query.pageSize + 1),
            last: Math.min(results.totalCount, (query.page + 1) * query.pageSize),
        },
    };
};

export const createConnectPagination = selector => connect({
    using: [selector],
    mapStateToProps: (pagination: Pagination) => watchComputedProps({
        watch: ['pageState'],
        compute: state => ({
            pageState: getPageState(pagination, state),
            urls: {
                setFilter: pagination.selectors.setFilterURL.bind(null, state),
                replaceFilter: pagination.selectors.replaceFilterURL.bind(null, state),
                removeFilter: pagination.selectors.clearFilterURL.bind(null, state),
                setPage: pagination.selectors.setPageURL.bind(null, state),
                updatePage: pagination.selectors.updatePageURL.bind(null, state),
                clearAllFilters: pagination.selectors.clearAllFiltersURL.bind(null, state),
                removeAttributeFilter: pagination.selectors.clearFilterKeyURL.bind(null, state),
            },
            isLoading: true,
        }),
    }),
    mapDispatchToProps: pagination => dispatch => ({
        actions: {
            setPageSize: x => dispatch(pagination.actions.setPageSize(x)),
            setSort: (x, y) => dispatch(pagination.actions.setSort(x, y)),
            setFilter: (k, vs) => dispatch(pagination.actions.setFilter(k, vs)),
        },
    }),
});

export const createConnectOverridePagination = selector => connect({
    using: [selector],
    mapStateToProps: (pagination: OverridePagination) => watchComputedProps({
        watch: ['pageState'],
        compute: state => ({
            pageState: getOverridePageState(pagination, state),
            urls: {
                setFilter: pagination.selectors.setFilterURL.bind(null, state),
                replaceFilter: pagination.selectors.replaceFilterURL.bind(null, state),
                removeFilter: pagination.selectors.clearFilterURL.bind(null, state),
                setPage: pagination.selectors.setPageURL.bind(null, state),
                updatePage: pagination.selectors.updatePageURL.bind(null, state),
                clearAllFilters: pagination.selectors.clearAllFiltersURL.bind(null, state),
                removeAttributeFilter: pagination.selectors.clearFilterKeyURL.bind(null, state),
            },
            isLoading: true,
        }),
    }),
    mapDispatchToProps: pagination => dispatch => ({
        actions: {
            setPageSize: x => dispatch(pagination.actions.setPageSize(x)),
            setSort: (x, y) => dispatch(pagination.actions.setSort(x, y)),
            setFilter: (k, vs) => dispatch(pagination.actions.setFilter(k, vs)),
        },
    }),
});
