import React from 'react';
import { connectSearchPage } from '@silkpwa/module/react-component/connect-search-page';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import styles from './styles.css';

const RecommendationComponent = ({
    searchRecommendations,
    shouldShowRecommendations,
}) => {
    const t = usePhraseTranslater();
    if (!searchRecommendations.length || !shouldShowRecommendations) return null;
    return (
        <div className={styles.container}>
            <span>{t('Search Suggestions')}</span>
            {searchRecommendations.map(e => <a href={e.link}>{e.query_text}</a>)}
        </div>
    );
};

export const SearchRecommendations = connectSearchPage(RecommendationComponent);
