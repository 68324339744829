import React from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { DropDown } from 'ui/component/drop-down';

const createOptions = (sortOptions, t) => {
    const options = [];


    const createOption = (o, direction, description) => ({
        id: o.attribute + direction,
        label: t(`${o.label} - ${description}`),
        attribute: o.attribute,
        direction,
    });

    const pushOption = (optionObj, direction: string, description: string) => {
        const option = createOption(optionObj, direction, description);
        if (!option.label.trim()) {
            return false;
        }

        options.push(option);
        return true;
    };

    sortOptions.forEach((o) => {
        let ascDescription;
        let descDescription;
        if (o.label.toLowerCase().indexOf('name') !== -1) {
            ascDescription = t('A to Z');
            descDescription = t('Z to A');
        } else {
            ascDescription = t('Low to High');
            descDescription = t('High to Low');
        }
        pushOption(o, 'asc', ascDescription);
        pushOption(o, 'desc', descDescription);
    });

    return options;
};

export const Sort = ({
    sortOptions,
    setSort,
    sortBy,
    sortDir,
}) => {
    const t = usePhraseTranslater();

    return (
        <DropDown
            label={t('SORT BY')}
            options={createOptions(sortOptions, t)}
            selected={`${sortBy}${sortDir}`}
            handleSelect={(option) => {
                setSort(option.attribute, option.direction);
            }}
        />
    );
};
