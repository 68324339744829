const getOption = (attribute, option, value) => {
    if (!option && attribute.type !== 'Price') return undefined;
    if (!option) {
        return {
            currency: attribute.currency,
            range: {
                low: value.split('-')[0],
                high: value.split('-')[1],
            },
        };
    }

    return option;
};

const extractAttributes = (atts, urls, actions, selections) => atts.map(att => ({
    id: att.id,
    label: att.label,
    type: att.type,
    getUrl: v => urls.setFilter(att.id, v),
    clearUrl: urls.removeAttributeFilter(att.id),
    setValues: vs => actions.setFilter(att.id, vs),
    options: att.options.map((o) => {
        const selected = (selections[att.id] || []).filter(x => x === o.id).length > 0;
        const chooseURL = urls.setFilter(att.id, o.id);

        return {
            ...o,
            selected,
            chooseURL,
        };
    }),
}));

const extractSelections = (attributes, options, selections, urls) => Object.keys(selections)
    .filter(k => attributes[k])
    .map(k => ({
        clear: urls.removeAttributeFilter(k),
        attribute: attributes[k],
        options: selections[k]
            .map(v => getOption(attributes[k], options[v], v))
            .filter(x => x),
    }));

export const FilterList = ({
    children,
    urls,
    pageState,
    actions,
}) => {
    const render: any = children;

    const childProps = {
        clearAll: urls.clearAllFilters(),
        attributes: extractAttributes(
            pageState.filterOptions,
            urls,
            actions,
            pageState.filterValues,
        ),
        selections: extractSelections(
            pageState.attributes,
            pageState.options,
            pageState.filterValues,
            urls,
        ),
    };

    return render(childProps);
};
