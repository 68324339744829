import React from 'react';
import { ValueSlider } from '@silkpwa/module/react-component/value-slider';
import { UseState } from '@silkpwa/module/react-component/use-state';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import styles from './style.css';

interface SliderFilterProps {
    minValue: number;
    maxValue: number;
    increment: number;
    getRangeText(minValue: number, maxValue: number): string;
    getRangeUrl(minValue: number, maxValue: number): string;
    buttons?: JSX.Element;
}

export const SliderFilter = ({
    minValue: initialMinValue,
    maxValue: initialMaxValue,
    getRangeText,
    increment,
    getRangeUrl,
    buttons,
}: SliderFilterProps) => {
    const t = usePhraseTranslater();
    return (
        <UseState initialState={{ minValue: initialMinValue, maxValue: initialMaxValue }}>
            {([{ minValue, maxValue }, setValues]) => (
                <div className={styles.sliderWrap}>
                    <div className={styles.range}>
                        {getRangeText(minValue, maxValue)}
                    </div>
                    <ValueSlider
                        min={initialMinValue}
                        max={initialMaxValue}
                        left={minValue}
                        right={maxValue}
                        step={increment}
                        onChange={(newMinValue, newMaxValue) => setValues({
                            minValue: newMinValue,
                            maxValue: newMaxValue,
                        })}
                        buttonColor={styles.buttonColor}
                        backgroundColor={styles.backgroundColor}
                        filledColor={styles.filledColor}
                    />
                    <div className={styles.buttonWrap}>
                        <a href={getRangeUrl(minValue, maxValue)}>{t('Apply')}</a>
                        {buttons}
                    </div>
                </div>
            )}
        </UseState>
    );
};
