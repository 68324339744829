import React from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { SliderFilter } from '../slider-filter';

const getRangeText = unit => (minValue, maxValue) => (
    `${minValue.toFixed(2)}${unit} - ${maxValue.toFixed(2)}${unit}`
);

const extractSliderWeights = (options) => {
    const weights = options.map(o => ({
        weight: Number(o.label.replace(/\s.*/, '')),
        unit: o.label.replace(/^(.*)\s(.*)$/, '$2'),
        id: o.id,
    }));

    return weights.filter(w => !Number.isNaN(w.weight));
};

const getRangeUrl = (getUrl, weights) => (minValue, maxValue) => {
    const options = weights
        .filter(w => w.weight >= minValue && w.weight <= maxValue)
        .map(w => w.id);

    return getUrl(options);
};

export const FabricWeightSlider = ({ attribute: { options, clearUrl, getUrl } }) => {
    const t = usePhraseTranslater();
    const weights = extractSliderWeights(options);
    if (!weights.length) return null;

    const [{ unit }] = weights;
    const minValue = Math.floor(Math.min(...weights.map(x => x.weight)));
    const maxValue = Math.ceil(Math.max(...weights.map(x => x.weight)));
    const increment = (maxValue - minValue) / weights.length;
    const selected = options.filter(x => x.selected).length > 0;

    return (
        <SliderFilter
            minValue={minValue}
            maxValue={maxValue}
            increment={increment}
            getRangeText={getRangeText(unit)}
            getRangeUrl={getRangeUrl(getUrl, weights)}
            buttons={selected
                ? (
                    <a href={clearUrl}>{t('Clear Selections')}</a>
                )
                : null
            }
        />
    );
};
