import React from 'react';
import { classes } from '@silkpwa/module/util/classes';
import { Pagination } from '@silkpwa/module/react-component/pagination';
import { HeadTag } from '@silkpwa/module/react-component/head-tag';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import styles from './style.css';

const PageButton = ({
    href,
    selected,
    disabled,
    text,
}) => (
    <a
        href={href}
        className={classes({
            [styles.disabled]: disabled,
            [styles.selected]: selected,
        })}
    >
        {text}
    </a>
);

const NumberButton = ({
    href,
    selected,
    disabled,
    page,
}) => (
    <PageButton
        href={href}
        selected={selected}
        disabled={disabled}
        text={page}
    />
);

const NextButton = ({ href, selected, disabled }) => {
    const t = usePhraseTranslater();
    if (disabled) return null;

    return (
        <React.Fragment>
            <PageButton
                href={href}
                selected={selected}
                disabled={disabled}
                text={t('Next')}
            />
            <HeadTag tag="link" rel="next" href={href} />
        </React.Fragment>
    );
};

const PreviousButton = ({ href, selected, disabled }) => {
    const t = usePhraseTranslater();
    if (disabled) return null;

    return (
        <React.Fragment>
            <PageButton
                href={href}
                selected={selected}
                disabled={disabled}
                text={t('Prev')}
            />
            <HeadTag tag="link" rel="prev" href={href} />
        </React.Fragment>
    );
};

const FirstButton = ({ href, selected, disabled }) => {
    const t = usePhraseTranslater();
    if (disabled) return null;

    return (
        <PageButton
            href={href}
            selected={selected}
            disabled={disabled}
            text={t('First')}
        />
    );
};

const LastButton = ({ href, selected, disabled }) => {
    const t = usePhraseTranslater();
    if (disabled) return null;

    return (
        <PageButton
            href={href}
            selected={selected}
            disabled={disabled}
            text={t('Last')}
        />
    );
};

export const PaginationButtons = ({ pageState, urls, maxButtons }) => {
    if (pageState.pageCount === 1) {
        return null;
    }
    return (
        <div className={styles.paginationButtons}>
            <Pagination
                NumberButton={NumberButton}
                NextButton={NextButton}
                PreviousButton={PreviousButton}
                FirstButton={FirstButton}
                LastButton={LastButton}
                pageState={pageState}
                maxButtons={maxButtons}
                urls={urls}
            />
        </div>
    );
};
