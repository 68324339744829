import React from 'react';
import { getQueryParamByName } from 'ui/util/get-query-param-by-name';
import { SliderFilter } from '../slider-filter';


const getMinPrice = options => Math.min(
    ...options.map(o => Math.floor(o.range.low)),
);

const getMaxPrice = (options, increment) => Math.max(
    ...options.map(o => Math.ceil(
        o.range.high === undefined
            ? Number(o.range.low) + increment
            : o.range.high,
    )),
);

const createGetRangeText = options => (minValue, maxValue) => {
    const [{ currency }] = options;
    return `${currency}${minValue} - ${currency}${maxValue}`;
};

function createGetRangeUrl(getUrl) {
    return (minValue, maxValue) => {
        const url: string = getUrl();
        const priceQuery = `price=${minValue}-${maxValue}`;
        const qpSep = url.includes('?') ? '&' : '?';
        if (url.includes('price=')) {
            return url.replace(/price=([^&]*)/, priceQuery);
        }

        return `${url}${qpSep}${priceQuery}`;
    };
}

export const PriceSliderFilter: React.FC<any /* TODO: fix any */> = ({ attribute: { options, getUrl } }) => {
    const increment = 1;
    const priceParam = getQueryParamByName('price');

    let left = null;
    let right = null;
    if (priceParam) {
        const matches = priceParam.match(/\d+/g);
        if (matches) {
            const [first, second] = matches.map(Number);
            left = first;
            right = second;
        }
    }

    const minPrice = left || getMinPrice(options);
    const maxPrice = right || getMaxPrice(options, increment);
    const getRangeText = createGetRangeText(options);
    const getRangeUrl = createGetRangeUrl(getUrl);

    return (
        <SliderFilter
            minValue={minPrice}
            maxValue={maxPrice}
            getRangeText={getRangeText}
            increment={increment}
            getRangeUrl={getRangeUrl}
        />
    );
};
