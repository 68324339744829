import React from 'react';
import { classes } from '@silkpwa/module/util/classes';
import styles from './style.css';

interface ApplyFilterProps {
    option: {
        id: string;
        selected: boolean;
        chooseURL: string;
        title: string;
        count: number;
    };
    children: React.ReactNode;
    attributeId: string;
}
const ApplyFilter: React.FC<ApplyFilterProps> = ({ option, children, attributeId }) => {
    const isColorFilter = attributeId === 'color_filters';

    let url = option.chooseURL;
    if (option.selected) {
        const params = url.split('?');
        const left = params[0];
        let right = params[1];
        let tmpRight = right.split('&');
        if (!tmpRight) {
            tmpRight[0] = right;
        }
        let attributeParam = tmpRight.find(param => param.includes(attributeId));

        if (attributeParam) {
            const colorFiltersValue = attributeParam.split('=')[1];
            const options = colorFiltersValue.split(',');

            if (options.length > 1) {
                const optionIndex = options.indexOf(option.id);
                if (optionIndex !== -1) {
                    options.splice(optionIndex, 1);
                    const updatedValue = options.join(',');
                    attributeParam = attributeParam.replace(colorFiltersValue, updatedValue);
                }
            } else {
                tmpRight = tmpRight.filter(param => !param.includes(attributeId));
            }

            tmpRight = tmpRight.map(param => (param.includes(attributeId) ? attributeParam : param));
        }
        right = tmpRight.join('&');
        url = `${left}?${right}`;
    }

    return (
        <a href={url} title={option.title} className={styles.link}>
            <div
                className={classes(styles.option, {
                    [styles.selected]: option.selected && !isColorFilter,
                })}
                style={{ display: isColorFilter ? 'inline-block' : '' }}
            >
                {isColorFilter ? (
                    children
                ) : (
                    <>
                        <input type="checkbox" checked={option.selected} />
                        <span className={styles.title}>
                            {children}
                            {' '}
                        </span>
                        <span className={styles.counter}>
                            (
                            {option.count}
                            )
                        </span>
                    </>
                )}
            </div>
        </a>
    );
};


export const Base = Component => ({ attribute }) => {
    const isColorFilter = attribute.id === 'color_filters';
    return (
        <div className={classes(styles.list, isColorFilter ? styles.colorFilters : '')}>
            {attribute.options.map(o => (
                <ApplyFilter key={o.id} option={o} attributeId={attribute.id}>
                    <Component option={o} attributeId={attribute.id} />
                </ApplyFilter>
            ))}
        </div>
    );
};
