import React from 'react';
import {
    Swatches, TextSwatch, ApplyFilter,
} from 'ui/component/product/swatches';
import textSwatch from './text-swatch.css';

export const FilterTextSwatches = ({ handleClick, attribute }) => (
    <Swatches
        theme={textSwatch}
        SelectionBehavior={ApplyFilter}
        Swatch={TextSwatch}
        handleClick={handleClick}
        attribute={attribute}
    />
);
