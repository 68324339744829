import React from 'react';
import { TextOptionList } from 'ui/component/product/option-list';
import { PriceSliderFilter } from 'ui/component/product/price-slider-filter';
import { FabricWeightSlider } from 'ui/component/product/fabric-weight-slider';
import { createFilterAccordion } from './filter-accordion';
import { FilterColorSwatches, FilterTextSwatches } from '../swatches';

const getOptionsWithTitles = (attribute, option) => {
    if (option.count === undefined) return '';

    if (attribute.type === 'ColorSwatches') {
        return `${option.label} (${option.count} items)`;
    }

    return `${option.count} items`;
};

const createFilterAttribute = Components => ({ attribute }) => {
    const Component = Components[attribute.label] || Components[attribute.type];

    const newAttribute = {
        ...attribute,
        options: attribute.options.map(o => ({
            ...o,
            title: getOptionsWithTitles(attribute, o),
        })),
    };

    return <Component attribute={newAttribute} />;
};

export const FilterAttribute = createFilterAttribute({
    ColorSwatches: createFilterAccordion(FilterColorSwatches),
    TextSwatches: createFilterAccordion(FilterTextSwatches),
    Price: createFilterAccordion(PriceSliderFilter),
    Text: createFilterAccordion(TextOptionList),
    'Fabric Weight': createFilterAccordion(FabricWeightSlider),
});
