import React from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import styles from './style.css';

export const Selections = ({ clearAll }) => {
    const t = usePhraseTranslater();
    return (
        <div className={styles.box}>
            <div className={styles.filterLabel}>{t('Active Filters')}</div>
            <div className={styles.selections}>
                <div>
                    <a className={styles.clearAll} href={clearAll}>
                        {t('Clear All Filters')}
                    </a>
                </div>
            </div>
        </div>
    );
};
