import React from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { Filters } from '../../components/filters';
import { MobileAccordion } from '../../components/mobile-accordion';
import styles from './styles.css';

export const MobileTopContent = ({ pageState, urls, actions }) => {
    const t = usePhraseTranslater();
    return (
        <div className={styles.mobileTopContent}>
            <MobileAccordion text={t('Filter By')}>
                <Filters
                    pageState={pageState}
                    urls={urls}
                    actions={actions}
                />
            </MobileAccordion>
        </div>
    );
};
