import React from 'react';
import { FilterList } from '@silkpwa/module/react-component/filter-list';
import { Content } from './content';

export const Filters = ({ pageState, urls, actions }) => (
    <FilterList
        pageState={pageState}
        urls={urls}
        actions={actions}
    >
        {({ attributes, selections, clearAll }) => (
            <Content
                attributes={attributes}
                selections={selections}
                clearAll={clearAll}
            />
        )}
    </FilterList>
);
