import React from 'react';
import {
    Swatches, ColorSwatch, ApplyFilter,
} from 'ui/component/product/swatches';
import colorSwatch from './color-swatch.css';

export const FilterColorSwatches = ({ handleClick, attribute }) => (
    <Swatches
        theme={colorSwatch}
        SelectionBehavior={ApplyFilter}
        Swatch={ColorSwatch}
        handleClick={handleClick}
        attribute={attribute}
    />
);
