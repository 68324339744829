import React from 'react';
import { useDesktopMediaQuery, useLargeMobileMediaQuery } from 'chefworks-theme/src/hooks/useScreenType';

export const ResponsiveGrid = ({ renderGrid }) => {
    const isDesktop = useDesktopMediaQuery();
    const isMobile = useLargeMobileMediaQuery();
    let gridSize = 2;
    if (isDesktop) {
        gridSize = 4;
    } else if (isMobile) {
        gridSize = 3;
    }
    return (
        <div>
            {renderGrid(gridSize)}
        </div>
    );
};
