import React from 'react';
import viewWishlistIcon from 'assets/images/mockImgs/wishlistHeart.png';
import { connectWishlist } from '@silkpwa/module/react-component/connect-wishlist';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { isPunchoutStoreMode } from '@silkpwa/module/store-mode/';
import { ItemList } from '../item-list';

const WishlistImpl = ({ wishlistItems, deleteWishlistItem }) => {
    const t = usePhraseTranslater();
    if (isPunchoutStoreMode()) return null;
    return (
        <ItemList
            items={wishlistItems}
            headerMessage={t('MY WISHLIST')}
            emptyMessage={t('You have no items in your wishlist.')}
            removeItem={deleteWishlistItem}
            link={{
                text: t('VIEW WISHLIST'),
                icon: viewWishlistIcon,
                url: '/wishlist/index/index/',
            }}
            displayCount={3}
        />
    );
};

export const Wishlist = connectWishlist(WishlistImpl);
