import React from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { FilterAttribute } from '../filter-attribute';
import styles from './style.css';

export const Attributes = ({ attributes }) => {
    const t = usePhraseTranslater();
    return (
        <div className={styles.box}>
            <div className={styles.filterLabel}>{t('Filter By')}</div>
            <div className={styles.attributes}>
                {attributes.map(attribute => (
                    <FilterAttribute
                        key={attribute.id}
                        attribute={attribute}
                    />
                ))}
            </div>
        </div>
    );
};
