import React from 'react';
import { Attributes } from '../attributes';
import { Selections } from '../selections';
import styles from './style.css';

export const Content = ({ selections, attributes, clearAll }) => (
    <div
        style={{
            display: (
                selections.length === 0 &&
                attributes.length === 0
            ) ? 'none' : 'block',
        }}
        className={styles.filterList}
    >
        {selections.length > 0 && (
            <Selections clearAll={clearAll} />
        )}
        {attributes.length > 0 && (
            <Attributes attributes={attributes} />
        )}
    </div>
);
