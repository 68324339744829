import React, { Fragment } from 'react';

const range = (lower, upper) => {
    const res = [];

    // eslint-disable-next-line no-plusplus
    for (let i = lower; i <= upper; i++) {
        res.push(i);
    }

    return res;
};

const getPageRange = (total, current, maxButtons) => {
    const multiple = Math.floor((current - 1) / maxButtons);

    const lower = multiple * maxButtons + 1;
    const upper = Math.min(lower + maxButtons - 1, total);
    const actualLower = Math.max(1, upper - maxButtons + 1);

    return range(actualLower, upper);
};

const pageRange = (pageState, maxButtons) => getPageRange(
    pageState.pageCount,
    pageState.page,
    maxButtons || 5,
);

const renderPageButton = (Component, {
    page,
    urls,
    pageState,
    disabled,
}) => (
    <Component
        href={urls.setPage(page - 1)}
        disabled={pageState.page === page || disabled}
        selected={pageState.page === page}
        page={page}
    />
);

const isLastDisabled = pageState => (
    pageState.page === pageState.pageCount ||
    pageState.pageCount === 0
);

export const Pagination = ({
    NumberButton,
    PreviousButton,
    NextButton,
    FirstButton,
    LastButton,
    pageState,
    maxButtons,
    urls,
}) => (
    <Fragment>
        {renderPageButton(FirstButton, {
            page: 1,
            urls,
            pageState,
            disabled: false,
        })}
        {renderPageButton(PreviousButton, {
            page: pageState.page - 1,
            urls,
            pageState,
            disabled: pageState.page === 1,
        })}
        {pageRange(pageState, maxButtons).map(page => (
            <Fragment key={page}>
                {renderPageButton(NumberButton, {
                    page,
                    urls,
                    pageState,
                    disabled: false,
                })}
            </Fragment>
        ))}
        {renderPageButton(NextButton, {
            page: pageState.page + 1,
            urls,
            pageState,
            disabled: isLastDisabled(pageState),
        })}
        {renderPageButton(LastButton, {
            page: pageState.pageCount,
            urls,
            pageState,
            disabled: isLastDisabled(pageState),
        })}
    </Fragment>
);
