import { useEffect, useRef } from 'react';
import { useContainer, useContainerHook } from '@silkpwa/redux';
import { IScrolling } from '@silkpwa/module/scrolling';

/**
 * Provides a ref to attach to a child component that you
 * want to scroll to when a navigation event occurs that stays
 * on the same page.
 *
 * The default scroll behavior is smooth scrolling, but it can be
 * changed by specifying the behavior as the single argument to this
 * hook.
 */
export const useScrollOnNavigation = (behavior = 'smooth') => {
    const scrollRef = useRef<HTMLElement>();
    const isFirstRender = useRef(true);
    const scrolling = useContainer<IScrolling>('scrolling');
    const stateKey = useContainerHook<() => number>('useRouterStateKey');

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }
        scrolling.scrollIntoView(scrollRef.current, { behavior });
    }, [stateKey]);

    return scrollRef;
};
