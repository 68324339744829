import React from 'react';
import { AccessibleButton } from '@silkpwa/module/react-component/accessible-button';
import { UseState } from '@silkpwa/module/react-component/use-state';
import styles from './style.css';

export const MobileAccordion = ({ text, children }) => (
    <UseState>
        {([open, setOpen]) => (
            <div className={styles.mobileAccordion}>
                <AccessibleButton
                    tag="div"
                    className={styles.mobileAccordionHeader}
                    action={() => setOpen(!open)}
                >
                    <div className={styles.mobileAccordionHeaderText}>{text}</div>
                    {' '}
                    <i className="material-icons">{open ? 'remove' : 'add'}</i>
                </AccessibleButton>
                {open && children}
            </div>
        )}
    </UseState>
);
