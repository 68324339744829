import React from 'react';
import { AccessibleButton } from '@silkpwa/module/react-component/accessible-button';
import listClose from 'assets/images/mockImgs/listClose.svg';
import styles from './style.css';

export const Item = ({ item, removeItem }) => (
    <div className={styles.itemWrap}>
        <a href={item.product.product_url}>
            <img className={styles.itemImg} src={item.product.imgurl} alt="" />
            <p className={styles.description} title={item.product.name}>{item.product.name}</p>
        </a>
        <AccessibleButton
            tag="span"
            action={() => removeItem(item)}
        >
            <img className={styles.closeIcon} src={listClose} alt="" />
        </AccessibleButton>
    </div>
);
