import React from 'react';
import { ResponsiveGrid } from './responsive-grid';
import { ProductGrid } from './product-grid';
import { LoadingGrid } from './loading-grid';

export const Grid = ({ pageState, emptyContent }) => {
    const { items } = pageState;

    if (items.type === 'items' &&
        items.items.length > 0) {
        return (
            <ResponsiveGrid
                renderGrid={(columns: number) => (
                    <ProductGrid
                        columns={columns}
                        items={items.items}
                    />
                )}
            />
        );
    }

    if (items.type === 'items' &&
        items.items.length === 0) {
        return emptyContent;
    }

    if (items.type === 'loading') {
        return (
            <ResponsiveGrid
                renderGrid={(columns: number) => (
                    <LoadingGrid columns={columns} />
                )}
            />
        );
    }

    return null;
};
