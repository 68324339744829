import React from 'react';
import { PaginationButtons } from '../../components/pagination-buttons';
import styles from './styles.css';

export const PaginationSection = ({ pageState, urls }) => (
    <div className={styles.paginationSection}>
        <PaginationButtons
            pageState={pageState}
            urls={urls}
            maxButtons={3}
        />
    </div>
);
