import { connect } from '@silkpwa/redux';

export const connectSearchPage = connect({
    using: ['ecommerceSearchPage'],
    mapStateToProps: search => state => ({
        searchTerm: search.selectors.getSearchTerm(state),
        searchSuggestions: search.selectors.getSuggestions(state),
        searchRecommendations: search.selectors.getRecommendations(state),
    }),
});
