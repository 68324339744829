import React from 'react';
import { Item } from './item';
import styles from './style.css';

export const Items = ({ items, removeItem, link }) => (
    <React.Fragment>
        <div className={styles.compareListMain}>
            {items.map((item, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Item key={index} item={item} removeItem={removeItem} />
            ))}
        </div>
        <a className={styles.compareProducts} href={link.url}>
            <img className={styles.compareIcon} src={link.icon} alt="" />
            {link.text}
        </a>
    </React.Fragment>
);
