import React from 'react';
import { InteractiveProductCard } from 'ui/component/product/interactive-product-card';
import style from './style.css';

const Memoized = React.memo(InteractiveProductCard);

export const ProductGrid = ({
    items,
    columns,
}: { items: any[]; columns: number }) => (
    <div className={style.gridWrap}>
        {items.map(item => (
            <Memoized
                key={item.id}
                product={item}
                displayed={columns}
                location="category"
            />
        ))}
    </div>
);
