import React, { Component } from 'react';
import isEqual from 'lodash/isEqual';
import { getQueryParamByName } from 'ui/util/get-query-param-by-name';
import { classes } from '../../util/classes';
import styles from './style.css';

export class ValueSlider extends Component<any, any> {
    private slider;

    private _min;

    private _max;

    private _step;

    private _left;

    private _right;

    private _side;

    private _initial;

    private _startPos;

    constructor(props) {
        super(props);
        this.state = props;
        const paramValue = getQueryParamByName('price');
        if (paramValue) {
            const pattern = /\d+/g;
            const matches = paramValue.match(pattern);
            if (matches) {
                const values = matches.map(Number);
                const left = values[0];
                const right = values[1];
                this.state = { left, right };
            }
        }
    }

    /* Listen for mousemove anywhere on DOM and update state based
    on props */
    componentDidMount() {
        document.addEventListener('mousemove', this.move);
        this.updateValues();
    }

    /* Update state based on props */
    componentDidUpdate() {
        this.updateValues();
    }

    /* Clean up mousemove listener */
    componentWillUnmount() {
        document.removeEventListener('mousemove', this.move);
    }

    /* Gets the x coordinate of a mouse or touch event */

    // eslint-disable-next-line class-methods-use-this
    getX(ev) {
        const { clientX } = ev.changedTouches ? ev.changedTouches[0] : ev;
        return clientX;
    }

    /* Returns the value range of the slider (length of the value
    interval) */
    get range() {
        const { min, max } = this.props;
        return max - min;
    }

    /* Get min allowed value */
    get min() {
        const { min } = this.props;
        return min;
    }

    /* Get max allowed value */
    get max() {
        const { max } = this.props;
        return max;
    }

    /* Get the value of the left slider handle based on props */
    get left() {
        const { left } = this.props;
        if (left === undefined) return this.min;
        return left;
    }

    /* Get the value of the right slider handle based on props */
    get right() {
        const { right } = this.props;
        if (right === undefined) return this.max;
        return right;
    }

    /* Size of a step */
    get step() {
        const { step } = this.props;
        return step;
    }

    /* Max number of steps from min allowed */
    get maxSteps() {
        return this.range / this.step;
    }

    /* Get the percent values of the handles (which is used for rendering). */
    get handlePercents() {
        const { left, right } = this.state;
        const percentPerStep = (this.step / this.range) * 100;
        return {
            left: left * percentPerStep,
            right: right * percentPerStep,
        };
    }

    /* Tell the parent component about a change to the slider state */
    notify = (newState) => {
        const { onChange } = this.props;

        if (!onChange) return;

        onChange(...this.computeValues(newState));
    };

    /* Start tracking slider movement from the initial
    position of the specified handle once an event is triggered */
    start = side => (ev) => {
        this._side = side;
        // eslint-disable-next-line react/destructuring-assignment
        this._initial = this.state[this._side];
        this._startPos = this.percentOf(this.getX(ev));
    };

    /* Stop tracking the slider movement */
    stop = () => {
        this._side = undefined;
    };

    /* Handles mouse or finger movement. If the mouse or finger has moved
    enough to move the slider, and the slider move is valid, the state
    is updated accordingly */
    move = (ev) => {
        const { step } = this.props;

        this.checkMoving(ev);
        if (!this._side) return;

        const stopPos = this.percentOf(this.getX(ev));

        const newState = {
            ...this.state,
            [this._side]: this.compute({
                initial: this._initial,
                start: this._startPos,
                end: stopPos,
                range: this.range,
                step,
            }),
        };

        if (this.isValidState(newState) && !isEqual(this.state, newState)) {
            this._initial = newState[this._side];
            this._startPos = stopPos;
            this.setState(newState);
            this.notify(newState);
        }
    };

    /* Store a ref for the slider element */
    setSliderRef = (slider) => {
        this.slider = slider;
    };

    computeValues(newState) {
        return [
            this.min + this.step * newState.left,
            this.min + this.step * newState.right,
        ];
    }

    /* Compute the next value of a slider handle given how much
    the user moved mouse or finger. Returns initial +/- the correct
    number of steps that were moved. */

    // eslint-disable-next-line class-methods-use-this
    compute({
        initial, start, end, range, step,
    }) {
        const percentPerStep = step / range * 100;
        const percentDelta = end - start;
        const sign = percentDelta > 0 ? 1 : -1;
        const numSteps = Math.floor((Math.abs(percentDelta)) / percentPerStep);

        return initial + sign * numSteps;
    }

    /* Determines whether any props changed, and if so, updates the
    state */
    updateValues() {
        const {
            min,
            max,
            step,
            left,
            right,
        } = this.props;

        if (this._min !== min ||
            this._max !== max ||
            this._step !== step ||
            this._left !== left ||
            this._right !== right) {
            this._min = min;
            this._max = max;
            this._step = step;
            this._left = left;
            this._right = right;
            this.doUpdateValues();
        }
    }

    /* Updates the state of the left and right handles to reflect
    the left and right props */
    doUpdateValues() {
        const newState = {
            left: (this.left - this.min) / this.step,
            right: (this.right - this.min) / this.step,
        };
        if (this.isValidState(newState)) {
            this.setState(newState);
        }
    }

    /* For mouse events, check if button is still down. If not,
    stop the slider from tracking movement */
    checkMoving(ev) {
        if (ev.changedTouches) return;

        if (ev.buttons === 0) {
            this.stop();
        }
    }

    /* Check if a new state for the slider handles is valid before
    moving into that state */
    isValidState(state) {
        return state.left >= 0 &&
            state.right <= this.maxSteps &&
            state.right > state.left;
    }

    /* Gets the percent value of a pixel value, relative to the
    slider's rendered width */
    percentOf(pixelValue) {
        return (pixelValue / this.slider.clientWidth) * 100;
    }

    /* Render the current state of the slider. */
    render() {
        const {
            backgroundColor,
            buttonColor,
            filledColor,
        } = this.props;

        const { left, right } = this.handlePercents;

        const fillWidth = right - left;

        const [leftCurrent, rightCurrent] = this.computeValues(this.state);

        return (
            /* eslint-disable jsx-a11y/interactive-supports-focus */
            <div className={styles.sliderWrap}>
                <div className={classes(styles.slider, backgroundColor)} ref={this.setSliderRef}>
                    <div
                        className={classes(styles.leftBtn, buttonColor)}
                        style={{ left: `${left}%` }}
                        onMouseDown={this.start('left')}
                        onTouchMove={this.move}
                        onTouchEnd={this.stop}
                        onTouchStart={this.start('left')}
                        role="slider"
                        aria-valuemax={this.max}
                        aria-valuemin={this.min}
                        aria-valuenow={leftCurrent}
                    />
                    <div
                        className={classes(styles.propo, filledColor)}
                        style={{ left: `${left}%`, width: `${fillWidth}%` }}
                    />
                    <div
                        className={classes(styles.rightBtn, buttonColor)}
                        style={{ left: `${right}%` }}
                        onMouseDown={this.start('right')}
                        onTouchMove={this.move}
                        onTouchEnd={this.stop}
                        onTouchStart={this.start('right')}
                        role="slider"
                        aria-valuemax={this.max}
                        aria-valuemin={this.min}
                        aria-valuenow={rightCurrent}
                    />
                </div>
            </div>
            /* eslint-enable */
        );
    }
}
