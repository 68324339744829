import React from 'react';
import { DropDown as BaseDropDown } from '@silkpwa/module/react-component/drop-down';
import styles from './style.css';

export const StyledDropDown = ({
    onChange,
    label,
    value,
    children,
}) => (
    <div className={styles.dropDownContainer}>
        <div className={styles.dropDownLabel}>{label}</div>
        <BaseDropDown
            className={styles.dropDown}
            onChange={onChange}
            value={value}
        >
            {children}
        </BaseDropDown>
    </div>
);

export const DropDown = ({
    options,
    handleSelect,
    label,
    selected,
}) => (
    <StyledDropDown
        onChange={(ev) => {
            const index = ev.target.selectedIndex - 1;
            const item = options[index];
            handleSelect(item);
        }}
        value={selected}
        label={label}
    >
        {options.map(o => (
            <option
                key={o.id}
                data-test={o.id}
                value={o.id}
            >
                {o.label}
            </option>
        ))}
    </StyledDropDown>
);
