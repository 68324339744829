import React from 'react';
import { Base } from '../base';

const price = (opt, key) => `${opt.currency}${opt.range[key]}`;

export const getPrice = (opt) => {
    if (opt.range.high) {
        return `${price(opt, 'low')} - ${price(opt, 'high')}`;
    }
    return `${price(opt, 'low')}+`;
};

export const Price = ({ option }) => <span>{getPrice(option)}</span>;

export const PriceOptionList = Base(Price);
