import React from 'react';
import { classes } from '@silkpwa/module/util/classes';
import { Base } from '../base';
import styles from './style.css';

export const Text = ({ option, attributeId }) => {
    let textClassName = styles.text;
    let colorCircleClassName = '';
    let isSelectedClassName = '';
    let textOutput = option.label;
    if (attributeId === 'color_filters') {
        colorCircleClassName = styles.colorCircle;
        isSelectedClassName = option.selected ? styles.selectedColor : '';
        textOutput = '';
        const labelToClassName = {
            Black: styles.textColorFiltersBlack,
            Blue: styles.textColorFiltersBlue,
            Gray: styles.textColorFiltersGray,
            White: styles.textColorFiltersWhite,
            Brown: styles.textColorFiltersBrown,
            Red: styles.textColorFiltersRed,
            Green: styles.textColorFiltersGreen,
            Tan: styles.textColorFiltersTan,
            Orange: styles.textColorFiltersOrange,
            Purple: styles.textColorFiltersPurple,
            Yellow: styles.textColorFiltersYellow,
            Check: styles.textColorFiltersCheck,
            Stripes: styles.textColorFiltersStripes,
            Silver: styles.textColorFiltersSilver,
        };

        textClassName = labelToClassName[option.label] || styles.textColorFiltersPlaceholder;
    }

    return (
        <span className={classes(textClassName, colorCircleClassName, isSelectedClassName)}>
            {textOutput}
        </span>
    );
};

export const TextOptionList = Base(Text);
