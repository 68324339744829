import React from 'react';
import { Items } from './items';
import styles from './style.css';

export const ItemList = ({
    items,
    headerMessage,
    emptyMessage,
    removeItem,
    link,
    displayCount,
}) => (
    <div className={styles.compareList}>
        <p className={styles.compareHead}>{headerMessage}</p>
        {items.length > 0 && (
            <Items items={items.slice(0, displayCount)} removeItem={removeItem} link={link} />
        )}
        {items.length === 0 && (
            <span>{emptyMessage}</span>
        )}
    </div>
);
