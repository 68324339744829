import React from 'react';
import compareIcon from 'assets/images/mockImgs/compareIcon.png';
import { connectCompare } from '@silkpwa/module/react-component/connect-compare';
import { isPunchoutStoreMode } from '@silkpwa/module/store-mode';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { ItemList } from '../item-list';

const CompareImpl = ({ compareItems, removeCompare }) => {
    const t = usePhraseTranslater();
    if (isPunchoutStoreMode()) return null;
    return (
        <ItemList
            items={compareItems}
            headerMessage={t('COMPARE')}
            emptyMessage={t('You have no items to compare.')}
            removeItem={removeCompare}
            link={{
                text: t('COMPARE PRODUCTS'),
                icon: compareIcon,
                url: '/catalog/product_compare/index/',
            }}
            displayCount={3}
        />
    );
};

export const Compare = connectCompare(CompareImpl);
