import React from 'react';
import { CertonaRecommendations } from 'ui/component/product/product-widget/certona-recommendations';
import { Grid } from '../../components/grid';
import { SortSection } from '../sort-section';
import { SearchRecommendations } from '../search-recommendations';
import styles from './styles.css';

export const GridSection = ({
    pageState,
    actions,
    shouldDisplaySort,
    shouldShowRecommendations,
    emptyContent,
}) => (
    <div className={styles.productGridWrap}>
        <SortSection
            pageState={pageState}
            actions={actions}
            shouldDisplaySort={shouldDisplaySort}
        />
        <SearchRecommendations shouldShowRecommendations={shouldShowRecommendations} />
        <Grid
            pageState={pageState}
            emptyContent={emptyContent}
        />
        <SearchRecommendations shouldShowRecommendations={shouldShowRecommendations} />
        <CertonaRecommendations />
    </div>
);
